import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
//import Actions from "../components/actions"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import tw from "tailwind.macro"
//import { graphql } from "gatsby"
//import Img from "gatsby-image"

const Article = styled.article`
  ${tw`p-6 md:p-4`};
  p a {
    ${tw`no-underline text-primary`};
  }
`

const ThreeColWrapper = styled.div`
  ${tw`mb-20 md:flex md:mb-4 md:px-12 md:-mx-4`};
`
const ThreeColDiv = styled.div`
  ${tw`md:w-1/3 md:px-4 md:text-sm`};
`
export default ({ data }) => (
  <Layout>
    <Hero
      title={[
        "<a href='https://www.thinkwithgoogle.com/intl/en-gb/advertising-channels/mobile/milliseconds-earn-millions-why-mobile-speed-can-slow-or-grow-your-business/' title='Why mobile speed can slow or grow your business' target='_blank' rel='noopener noreferrer'>Speed equals revenue.</a> If your website is slow people will abandon it and move on.",
      ]}
      text={[
        "Users today expect fast digital experiences. <a href='https://thinkwithgoogle.com/marketing-resources/data-measurement/mobile-page-speed-new-industry-benchmarks/' title='benchmarks for mobile page speed' target='_blank' rel='noopener noreferrer'>Google's research shows</a> that 53% of people abandon a site if it takes more than 3 seconds to load.",
      ]}
    />

    <Article>
      <ThreeColWrapper>
        <ThreeColDiv>
          <h2>The Old (Slow) Way</h2>
          <p>
            Traditional content management systems (CMSs) like Wordpress or
            Squarespace are notoriously slow. They require you to manage
            multiple plugins, install security updates, troubleshoot custom code
            and pay ongoing hosting or subscription fees. And after all of that
            they still perform poorly. <strong>There is a better way.</strong>
          </p>
        </ThreeColDiv>
        <ThreeColDiv>
          <h2>The New (Fast) Way</h2>
          <p>
            Modern websites are built using{" "}
            <a
              href="https://jamstack.org"
              title="JAMstack"
              target="_blank"
              rel="noopener noreferrer"
            >
              JAMstack architecture
            </a>
            . They load incredibly fast even with multiple large image
            galleries. There are no hassles with security or plugins breaking
            and you can say goodbye to ongoing monthly fees because hosting is
            free.
          </p>
        </ThreeColDiv>
        <ThreeColDiv>
          <h2>Test Your Website</h2>
          <p>
            <a
              href="https://web.dev/measure"
              title="Google web performance test"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go here to test your website
            </a>{" "}
            using Google's free website analysis to see how your current website
            performs. If you have any questions about your results or would like
            to bring your content to the modern web for a lighting fast
            experience, don't hesitate to{" "}
            <Link to="/contact/">contact me for help</Link>.
          </p>
        </ThreeColDiv>
      </ThreeColWrapper>
    </Article>
  </Layout>
)

// export const query = graphql`
//   query {
//     benchmarks: file(
//       relativePath: { eq: "mobile-page-speed-new-industry-benchmarks.jpg" }
//     ) {
//       childImageSharp {
//         fluid(maxWidth: 915, quality: 70) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `
